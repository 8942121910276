import React, { Component, Fragment } from 'react'
import $ from 'jquery';
import Typed from 'typed.js';

class Footer extends Component {
    componentDidMount(){
        if ($('.typed-3').length) {
            var typed_strings2 = $(".typed-3").data('typed-items');
            typed_strings2 = typed_strings2.split(',')
            new Typed('.typed-3', {
              strings: typed_strings2,
              loop: true,
              typeSpeed: 50,
              backSpeed: 70,
              backDelay: 3000
            });
        }

        if ($('.typed-2').length) {
            var typed_strings = $(".typed-2").data('typed-items');
            typed_strings = typed_strings.split(',')
            new Typed('.typed-2', {
              strings: typed_strings,
              loop: true,
              typeSpeed: 100,
              backSpeed: 50,
              backDelay: 2000
            });
        }
    }

    render() {
        return (
            <Fragment>               
                <footer id="footer">
                    <div className="container">
                    <h3><span className="typed-3" data-typed-items="Suraj , Tripathi, Suraj Tripathi"></span></h3>
                    <p>Assuming design that are needed or not but launguage fulfill it by <span className="typed-2" data-typed-items="describe , develop, setup"></span>.</p>
                    <div className="social-links">
                        <a href="https://twitter.com" className="twitter" rel="noreferrer" target="_blank"><i className="bx bxl-twitter"></i></a>
                        <a href="https://www.facebook.com/thesurajtripathi" className="facebook" rel="noreferrer" target="_blank"><i className="bx bxl-facebook"></i></a>
                        {/* <a href="#" className="google-plus"><i className="bx bxl-skype"></i></a> */}
                        <a href="https://www.linkedin.com/in/suraj-tripathi/" className="linkedin" rel="noreferrer" target="_blank"><i className="bx bxl-linkedin"></i></a>
                    </div>
                    <div className="copyright">
                        &copy; Copyright <strong><span>Suraj Tripathi</span></strong>. All Rights Reserved
                    </div>
                    <div className="credits">
                        Thanks to <a href="https://bootstrapmade.com/" rel="noreferrer" target="_blank">BootstrapMade</a>
                    </div>
                    </div>
                </footer>
                <a href="#" className="back-to-top"><i className="bx bx-up-arrow-alt"></i></a>
                <div id="preloader"></div>
            </Fragment>
        )
    }
}
export default Footer;
import React, { Component } from 'react'
import $ from 'jquery';
import Typed from 'typed.js';

export default class Home extends Component {
    componentDidMount(){
        if ($('.typed').length) {
            var typed_strings = $(".typed").data('typed-items');
            typed_strings = typed_strings.split(',')
            new Typed('.typed', {
              strings: typed_strings,
              loop: true,
              typeSpeed: 100,
              backSpeed: 50,
              backDelay: 2000
            });
        }

        

    }

    render() {
        return (
            <React.Fragment>
            <section id="hero" className="d-flex flex-column justify-content-center">
            <div className="container" data-aos="zoom-in" data-aos-delay="100">
                <h1>Suraj Tripathi</h1>
                <p>I'm <span className="typed" data-typed-items="JS , NodeJs, and, Web Developer, Blogger"></span></p>
                <div className="social-links">
                    <a href="https://twitter.com" className="twitter" rel="noreferrer" target="_blank"><i className="bx bxl-twitter"></i></a>
                    <a href="https://www.facebook.com/thesurajtripathi" className="facebook" rel="noreferrer" target="_blank"><i className="bx bxl-facebook"></i></a>                   
                    <a href="https://www.linkedin.com/in/suraj-tripathi/" className="linkedin" rel="noreferrer" target="_blank"><i className="bx bxl-linkedin"></i></a>
                </div>
            </div>
            </section>
        </React.Fragment>
        )
    }
}

import React from 'react'

export default function NotFound() {
    return (
        <React.Fragment>
            <section id="resume" className="resume">
                <div className="container" data-aos="fade-up">
                    <div className="section-title">
                        <h2>404</h2>
                        <p>Opps! Page you loking is not found</p>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

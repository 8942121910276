import React, { Component } from 'react'
import $ from 'jquery'
import Skills from './Skills'
class About extends Component {
    
    constructor(props) {
        super(props)
    
        this.state = {
             
        }
    }
    
    calculate_age(dob) { 
        var diff_ms = Date.now() - dob.getTime();
        var age_dt = new Date(diff_ms);      
        return Math.abs(age_dt.getUTCFullYear() - 1970);
    }
    componentDidMount(){
        $('.progress .progress-bar').each(function() {
            $(this).css("width", $(this).attr("aria-valuenow") + '%');
          });
    }

    render() {
        return (
            <React.Fragment>
                <main id="main">
                    {/* ABOUT SECTION */}
                    <section id="about" className="about">
                        <div className="container" data-aos="fade-up">
                            <div className="section-title">
                            <h2>about me</h2>
                            <p>I'm software engineer and love to code.I designed and developed many web application,apps.I enjoyed to turned the complex problem simple.When i'm not coding ,you will get me cooking,gardning.I love to write tech blogs and reading books i fond of very much.</p>
                            </div>
                        </div>
                        <div className="row">                            
                            <div className="col-lg-12 pt-4 pt-lg-0 content">
                            <h3>Full Stack Web Developer.</h3>
                            <p className="font-italic">
                                I am full stack MEAN developer but i love JS,it give me a frequency to bounce the code <strong>up</strong> , up and up till a new sound or fear come to me.I also developed many of the products in php and wordpress.
                            </p>
                            <div className="row">
                                <div className="col-lg-6">
                                <ul>
                                    <li><i className="icofont-rounded-right"></i> <strong>Website:</strong> www.surajtripathi.com</li>
                                    <li><i className="icofont-rounded-right"></i> <strong>Phone:</strong> +91 9718 63 6780</li>
                                    <li><i className="icofont-rounded-right"></i> <strong>City:</strong> City : Greater Noida Uttar Pradesh, IN</li>
                                </ul>
                                </div>
                                <div className="col-lg-6">
                                <ul>
                                    <li><i className="icofont-rounded-right"></i> <strong>Age:</strong> 30</li>
                                    <li><i className="icofont-rounded-right"></i> <strong>Degree:</strong> Master</li>
                                    <li><i className="icofont-rounded-right"></i> <strong>Email:</strong> contact@surajtripathi.com</li>
                                    
                                </ul>
                                </div>
                            </div>
                            <p>
                               
                            </p>
                            </div>
                        </div>

                    </section>
                    {/* END ABOUT SECTION */}
                    {/* <Facts/> */}
                    <Skills/>
                    
                </main>
            </React.Fragment>
        )
    }
}

export default About

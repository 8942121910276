import React, { Component } from 'react'

class Resume extends Component {
    render() {
        return (
            <React.Fragment>
                <section id="resume" className="resume">
                    <div className="container" data-aos="fade-up">

                        <div className="section-title">
                            <h2>Resume</h2>
                            <p>Looking forward to an opportunity for working in a dynamic , challenging environment
where i can utilize my skills for developing my career and for the growth of the organization.</p>
                        </div>

                        <div className="row">
                            <div className="col-lg-6">
                                <h3 className="resume-title">Sumary</h3>
                                <div className="resume-item pb-0">
                                    <h4>Suraj Tripathi</h4>
                                    <p><em>Innovative and Full stack web developer with 5+ years of experience designing and developing web applications.</em></p>
                                    <ul>
                                        <li>Greate Noida , IN</li>
                                        <li>(+91) 9718 63 6780</li>
                                        <li>surajtripathi781@gmail.com</li>
                                    </ul>
                                </div>

                                <h3 className="resume-title">Education</h3>
                                <div className="resume-item">
                                    <h4>Master of Computer Application</h4>
                                    <h5>2011 - 2014</h5>
                                    <p><em>HIMT,Greater Noida IN </em></p>
                                    <p>Have completed 3 years of post graduation with securing 70+ marks in year 2014,during masters i &amp; team has been develop some informatic launguage signatures.</p>
                                </div>
                                <div className="resume-item">
                                    <h4>Bachelor of Science</h4>
                                    <h5>2007 - 2010</h5>
                                    <p><em>VBS Purvanchal University,Jaunpur, IN</em></p>
                                    <p>I have completed 3 year graduation program with physics , maths as primary subject and chemistry as optional.</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <h3 className="resume-title">Professional Experience</h3>
                                <div className="resume-item">
                                    <h4>Software Engineer</h4>
                                    <h5>2018 - Present</h5>
                                    <p><em>MIND, Noida, IN </em></p>
                                    <ul>
                                        <li>Lead in the design, development, and implementation of the graphic, layout, and production communication materials</li>
                                    </ul>
                                </div>
                                <div className="resume-item">
                                    <h4>Software Engineer</h4>
                                    <h5>2016 - 2017</h5>
                                    <p><em>Sphinx World Biz Pvt Ltd., Noida, IN</em></p>
                                    <ul>
                                        <li>Developed and designed various web appications in php based frameworks.</li>
                                    </ul>
                                </div>
                                <div className="resume-item">
                                    <h4>Software Engineer</h4>
                                    <h5>2015 - 2016</h5>
                                    <p><em>Velopsys Technologies, Noida, IN</em></p>
                                    <ul>
                                        <li>Developed numerous of applications programs (school and hospital management systems).</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="center-block">
                                    <button className="btn btn-primary">Download CV</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            </React.Fragment>
        )
    }
}

export default Resume;
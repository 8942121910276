import React, { Component } from 'react'

class Skills extends Component {
    render() {
        return (
            <React.Fragment>
                <section id="skills" className="skills section-bg">
                    <div className="container" data-aos="fade-up">

                        <div className="section-title">
                        <h2>Skills</h2>
                        </div>

                        <div className="row skills-content">

                        <div className="col-lg-6">

                            <div className="progress">
                            <span className="skill">HTML <i className="val">100%</i></span>
                            <div className="progress-bar-wrap">
                                <div className="progress-bar" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                            </div>

                            <div className="progress">
                            <span className="skill">CSS <i className="val">75%</i></span>
                            <div className="progress-bar-wrap">
                                <div className="progress-bar" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                            </div>

                            <div className="progress">
                            <span className="skill">JavaScript <i className="val">75%</i></span>
                            <div className="progress-bar-wrap">
                                <div className="progress-bar" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                            </div>

                            <div className="progress">
                            <span className="skill">ReactJs <i className="val">50%</i></span>
                            <div className="progress-bar-wrap">
                                <div className="progress-bar" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                            </div>

                            <div className="progress">
                            <span className="skill">Ionic <i className="val">40%</i></span>
                            <div className="progress-bar-wrap">
                                <div className="progress-bar" role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                            </div>

                        </div>

                        <div className="col-lg-6">

                            <div className="progress">
                            <span className="skill">PHP <i className="val">80%</i></span>
                            <div className="progress-bar-wrap">
                                <div className="progress-bar" role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                            </div>

                            <div className="progress">
                            <span className="skill">WordPress/CMS <i className="val">90%</i></span>
                            <div className="progress-bar-wrap">
                                <div className="progress-bar" role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                            </div>

                            <div className="progress">
                            <span className="skill">NodeJs <i className="val">65%</i></span>
                            <div className="progress-bar-wrap">
                                <div className="progress-bar" role="progressbar" aria-valuenow="65" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                            </div>

                            <div className="progress">
                            <span className="skill">Angular <i className="val">75%</i></span>
                            <div className="progress-bar-wrap">
                                <div className="progress-bar" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                            </div>


                            <div className="progress">
                            <span className="skill">Mysql <i className="val">70%</i></span>
                            <div className="progress-bar-wrap">
                                <div className="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                            </div>

                        </div>

                        </div>

                    </div>
                    </section>
            </React.Fragment>
        )
    }
}

export default Skills

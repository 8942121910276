import React, { Component, Fragment } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import $ from 'jquery';
import AOS from 'aos/dist/aos';
import Footer from './components/Footer'
import Header from './components/Header'
import Home from './components/Home';
import About from './components/About';
import Resume from './components/Resume';
import Portfolio from './components/Portfolio';
import Contact from './components/Contact';
import NotFound from './components/NotFound';

class App extends Component {

  aosInit() {
    console.log(AOS);
    AOS.init({
      duration: 1000,
      once: true
    });
  }

  componentDidMount() {
    $(window).on('load', function () {
      if ($('#preloader').length) {
        $('#preloader').delay(100).fadeOut('slow', function () {
          $(this).remove();
        });
      }
    });

    $(window).on('load', function () {
      AOS.init({
        duration: 1000,
        once: true
      });
    });  

  }

  render() {
    return (
      <Fragment>
        <Router>
          <Header />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/about">
                            <About/>
            </Route>
            <Route exact path="/resume" component={Resume} />
            <Route exact path="/portfolio" component={Portfolio} />
            <Route exact path="/contact" component={Contact} />
            <Route component={NotFound} />
          </Switch>
          <Footer />
        </Router>
      </Fragment>
    )
  }
}

export default App;